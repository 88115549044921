.box{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
}
.row{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: space-between;
}
.head{
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}
label{
    font-size: 16px;
}
hr{
    width: 100%;
}
.column{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.spacebetween{
    justify-content: space-between;
}

.light{
    color: #6c6c6c;
}