.App {
    height: 85vh;
    width: 100vw;
  }
  
  .map-container {
    height: 100%;
    width: 100%;
  }

  .map-content{
   display: flex;
   position: absolute;
   top: 100px;
   left: 10px;
   max-width: 350px;
   width: 90vw;
   padding: 10px;
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   background-color: white;
   flex-direction: column;
  }

  .map-options{
    display: flex;
    gap:15px;
  }
  
  .map-details{
    display: flex;
    margin-top:10px;
    /* background-color: #eaeaea; */
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }