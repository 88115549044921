.payment-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin: auto;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
}

.date-container {
    display: flex;
    gap: 20px;
    width: 35%;
    min-width: 280px;
    align-items: center;
}


.paymentModal-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentModal-container .button {
    margin: 50px 0;
}

.paymentModal-container .date-container {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;

}

.modal-header-rows {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.paymentModal-container .modal .modal-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
}

.paymentModal-container .modal {
    top: 20px;
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
}

.paymentModal-container .modal h3 {
    margin: 0;
}

.paymentModal-containerTop {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    z-index: 10;
}

.paymentModal-containerCalendar, .rdrDefinedRangesWrapper {
    width: 150px;
    margin-top: 4px;
}

.paymentModal-containerCalendar .dropdown,
.paymentModal-containerCalendar .dropdown button {
    height: 42px;
}

.rdrStaticRange,
.rdrStaticRangeSelected,
.rdrDateRangePickerWrapper {
    width: 150px !important;
}

.rdrInputRanges {
    display: none;
}

@media screen and (max-width: 768px) {

    .paymentModal-containerTop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}