body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100%;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

html {
  height: 100%;
}

/* *{
  outline: 1px solid black;
} */



.logoutCl {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100px !important;
  height: 40px !important;
  color: white !important;
  background: #6F52EA !important;
  border-radius: 10px !important;
  font-size: 17px !important;
  gap: 10px !important;
  cursor: pointer !important;
}