.dropdown {
    position: relative;
    height: 50px;
    background: #eaeaea;
    border-radius: 6px;
    width: 100%;
  }
  .dropdown button{
    background-color: transparent;
    border: none;
    height: 50px;
    min-width: 80px;
    width: 100%;
    background: #eaeaea;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    font-size: 16px;
  }
  .menu {
    position: absolute;
    list-style-type: none;
    margin: 5px 0;
    padding: 0px;
    border: 1px solid #cbcbcb;
    width: 200px;
    background: white;
    z-index: 1;
    max-height: 350px;
    overflow-y: scroll;
  }
  
  .menu > li {
    margin: 5px;
    background-color: white;
    border-bottom: 1px solid #f3f3f3;
  }
  
  .menu > li:hover {
    background-color: lightgray;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 8px;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
