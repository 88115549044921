.chart-table
 {
   display: flex;
   justify-content: center;
   gap: 30px
 }
 .table-wrap{
   width: 48%;
 }
 .chart-item {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 10px;
 }