
.full-container{
    background: linear-gradient(0deg, #6F52EA 0%, #000 245.6%);
    height: 100%;
    width: 100%;
    position: absolute;
}
.login-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: 600px;
    margin-top: 100px;
    background: white;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}
.text{
    color: #6F52EA;
    font-size: 48px;
    font-weight: 700;
}
.underline{
    width: 60px;
    height: 6px;
    background: #6F52EA;
    border-radius: 9px;
}
.inputs{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}
.forgot-password{
    padding-left: 20px;
    margin-top: 5px;
    color: #797979;
    font-size: 16px;
}
.forgot-password span{
    color: #6F52EA;
    cursor: pointer;
}
.submit-container{
    display: flex;
    gap: 30px;
    margin: 30px auto;
}
@media screen and (max-width: 596px ) {
    .submit-container{
        display: flex;
        flex-direction: column;
    }    
}
.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color:white;
    background: #6F52EA;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}
.gray{
    background: #eaeaea;
    color: #676767
}

.error_css{
    margin-top: -15px;
    color: red;
} 