.button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    color:white;
    background: #6F52EA;
    border-radius: 10px;
    font-size: 17px;
    gap:10px;
    cursor: pointer;
}
.gray{
    background: #d7d7d7;
    color: black;
}
.small{
    font-size: 14px;
    width: 80px;
    height: 40px;
}
.rounded{
    border-radius: 20px;
}
.full{
    width: 100%;
}
.red{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    color:red;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
}
.deleted{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color:red;
    border-radius: 10px;
    border: 1px solid red;
    font-size: 14px;
    cursor: pointer;
}
.black{
    background: transparent;
    color: black;
}
.active{
    text-decoration: underline;
    text-underline-offset: 10px;
}
