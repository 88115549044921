.rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #6F52EA !important;
}

.rdrInRange {
    color: #6F52EA !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
    background: 6F52EA;
}

@media screen and (max-width:650px) {
    .rdrDateRangePickerWrapper{
        display: flex;
        flex-direction: column;
    }
    
}