.border{
    border: 1px solid #b3b3b3;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
}

.date{
    font-size: 12px;
    color: rgb(99, 99, 99);
    text-align: center;
    margin-bottom: 8px;
}