.input{
    display: flex;
    align-items: center;
    /* height: 50px; */
    background: #eaeaea;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    width: -webkit-fill-available;
}
.input input{
    height: 50px;
    width: 100%;
    background: transparent;
    border:none;
    outline: none;
    font-size: 16px;
}
.small{
    height: 40px !important;
    font-size: 14px !important;
    text-align: center;
}