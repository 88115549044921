.table-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
}
.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
}
.tab-container{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.tab{
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
}
.line{
    text-decoration: underline;
    text-underline-offset: 8px;
    color: #6F52EA;
}
h2{
    margin: 5px;
    font-size: 30px;
}
.page-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.modal-container{
    backdrop-filter: brightness(0.5);
    width: 100%;
    min-height: calc(100% + 750px);
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
}
.modal{
    position: relative;
    z-index: 1; 
    left: 0;
    right: 0;
    top: 100px;
    margin: auto;
    width: 90vw;
    max-width: 650px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    border: none;
}
.modal-header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}
.modal-content{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 10px;
}
.row{
    display: flex;
    flex-direction: row;
    gap:20px;
}
.hidden{
    display: none !important;
}
.cell-item{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.image{
    border-radius: 50px;
    object-fit: cover;
}

.detail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    margin-top: 40px;
}
.detail-header{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}
.bold{
    font-weight: 600;
    font-size: 18px;
}
div{
    font-size: 14px;
}
.red{
    color: red
}
.green{
    color: green;
    display: flex;
    justify-content: center;
}
.flex{
    display: flex;
    align-items: center;
    gap: 5px
}

.detail-documents{
    margin-top: 20px;    
}
hr{
    margin-top: 10px;
}
.documents-container{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.document{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.document .documentl{
    width: calc(100% - 180px);
    min-width: 115px;
}
.check{
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 5px
}
a:-webkit-any-link {
    text-decoration: none;
    justify-content: center;
}
@media screen and (max-width: 750px){
    .flex{
        flex-direction: column;
        align-items: start;
    }
    .document{
        flex-direction: column;
    }
    .documents-container {
        flex-direction: column;
    }

}


input[type='file']{
    background-color:transparent;
}
.customerFileBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.customerFileBtn span{
    width: 150px;
    margin-right: 95px;
    font-size: 16px;
}
.customerFileBtn input[type='file'] {
    padding:6px 0 0 7px;
    cursor: pointer;
    color: transparent;
    margin-left: -300px;
    &::-webkit-file-upload-button {
        display: none;
    }
    &::file-selector-button {
        display: none;
    }
}

.customerFileBtn label:hover {
    background-color: #dde0e3;
    cursor: pointer;
}

.customerFileBtn svg{
    width: 30px;
    height: 30px;
}

.addNewTaxi .customerFileBtn{
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 100%;
    background-color: #6F52EA;
    cursor: pointer;
}

.addNewTaxi img{
    border-radius: 100%;
}
.addNewTaxi img:first-child{
    border: 2px solid black;
}

.imageBtns{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: -20px;
}

.imageBtns .inputGroupFile{
    background-color: transparent;
    color: transparent;
    /* margin-right: 50px; */
    margin-left: -10px;
    width: 114px;
}

.imageBtns span{
    color: white;
    font-size: 20px;
    margin-left: 3px;
    margin-bottom: 5px;
    cursor: pointer;
}

.react-datepicker-wrapper {
    width: 100%;
    height: 52px;
}
.react-datepicker__input-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__input-container input {
    padding: 0 !important;
    height: 100%;
    font-size: 16px;
}

.downloadInput{
    width: 150px;
    /* margin-left: -385px; */
    background-color: transparent;
}

.downloadModalBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.onlineContainer{
    font-size: 18px;
    font-weight: 500;
    color: white;
    background: #f6802c;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
}