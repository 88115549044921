.rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 30px;
    height: 33px;
    text-align: center;
    color: #6F52EA !important;
}

.rdrInRange {
    color:#6F52EA!important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
    background: #6F52EA;
    color: white;
}

.paymentModal-container .rdrCalendarWrapper, .paymentModal-container .rdrDateRangeWrapper{
    display: none;
}